import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import React, { lazy, Suspense } from "react";

// import App from "App";
const App = lazy(() => import("App"));

// Context Provider
import { AdminControllerProvider } from "context";

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <AdminControllerProvider>
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <App/>
      </Suspense>
    </BrowserRouter>
  </AdminControllerProvider>
);